import React, {useEffect} from 'react';
import clsx from 'clsx';
import type, {FC, ReactNode} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {useTranslation,} from 'react-i18next';
import {Roles} from 'src/types/roles';
import {Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles, Theme} from '@material-ui/core';
import {
    Briefcase as BriefcaseIcon,
    Calendar as CalendarIcon,
    ShoppingCart as ShoppingCartIcon,
    Folder as FolderIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    UserPlus as UserPlusIcon,
    AlertCircle as AlertCircleIcon,
    Trello as TrelloIcon,
    User as UserIcon,
    Layout as LayoutIcon,
    Edit as EditIcon,
    DollarSign as DollarSignIcon,
    Mail as MailIcon,
    MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    Share2 as ShareIcon,
    Users as UsersIcon,
    Grid as GridIcon
} from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import Logo from 'src/components/Logo';
import {useHistory, useParams} from "react-router-dom";
import BillIcon from 'src/components/CustomIcon/BillIcon';
import ManageBilling from 'src/components/CustomIcon/ManageBilling';
import AdminDashboardIcon from 'src/components/CustomIcon/AdminDashboard';
import POCIcon from 'src/components/CustomIcon/POCIcon';
import ProviderDashboardIcon from 'src/components/CustomIcon/ProviderDashboardIcon';
import ExerciseIcon from 'src/components/CustomIcon/ExerciseIcon';
import PatientChartProfile from 'src/components/CustomIcon/PatientChartProfile';
import PatientsIcon from 'src/components/CustomIcon/PatientsIcon';
import ClinicalDashboard from 'src/components/CustomIcon/ClinicalDashboard';
import ManageUserIcon from 'src/components/CustomIcon/UserIcon';
import UserNewIcon from 'src/components/CustomIcon/UserNewIcon';
import ManagereportsIcon from 'src/components/CustomIcon/ManagereportsIcon';
import ConfigBlueIcon from 'src/components/CustomIcon/ConfigBlueIcon';
import ManageSchedulingIcon from 'src/components/CustomIcon/ManageSchedulingIcon';
import ManageAvailabilityIcon from 'src/components/CustomIcon/ManageAvailabilityIcon';
import PositioningIcon from 'src/components/CustomIcon/PositioningIcon';


interface NavBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    openSidebar: boolean;
}

interface Item {
    href?: string;
    icon?: ReactNode;
    info?: ReactNode;
    items?: Item[];
    title: string;
}

interface Section {
    items: Item[];
    subheader: string;
}

function renderNavItems({items, pathname, depth = 0}: { items: Item[]; pathname: string; depth?: number; }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({acc, item, pathname, depth}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth}: { acc: any[]; pathname: string; item: Item; depth: number; }) {
    const key = item.title + depth;
    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title}/>
        );
    }

    return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
    mobileDrawer: {
        width: 256
    },
    drawer: {
        width: 256,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    content: {
        flexGrow: 1,
        padding: 0,
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    logoBox: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('lg')]: {
            justifyContent: "flex-start",
            padding: "5px 16px",
            background: "#ffffff",
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1)
        },
    },

    drawerOpen: {
        width: 256,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& .labeldr': {
            display: "flex"
        }
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 25,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 25,
        },
        '& .labeldr': {
            display: "none"
        }
    },

    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
}));

const NavBar: FC<NavBarProps> = ({onMobileClose, openMobile, openSidebar}) => {
    const classes = useStyles();
    const location = useLocation();
    const {user} = useAuth();
    let sections: Section[] = [];
    let itemsadd: Item[] = [];
    let itemsaddqa: Item[] = [];
    const roles = user.roles[0];
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const urlParams = new URLSearchParams(window.location.search);
    const qatest = urlParams.get('qtest');
    let isqatestMode = !qatest;
    const {t, i18n} = useTranslation(['en-us', 'en-uk', 'en']);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    if (roles == Roles.SUPER_ADMIN || roles == Roles.PRACTICE_ADMIN || roles == Roles.ORG_ADMIN) {
        itemsadd.push({
            title: t('menu.label.listorganization'),
            href: '/app/management/organizations',
            icon: UserPlusIcon
        });

        itemsadd.push({
            title: t('menu.label.listuser'),
            href: '/app/management/users',
            icon: ManageUserIcon
        });
    }


    if (roles == Roles.PRACTICE_ADMIN || roles == Roles.ORG_ADMIN || roles == Roles.CSS || roles == Roles.PROVIDER) {
        itemsadd.push({
            title: t('menu.label.listpatient'),
            href: '/app/management/patients',
            icon: PatientsIcon
        });
    }

    if (roles == Roles.CSS || roles == Roles.PROVIDER) {
        itemsadd.push({
            title: t('menu.label.listclinical'),
            href: '/app/clinical/list',
            icon: UserNewIcon
        });
    }

    // if (roles == Roles.SUPER_ADMIN) {
    //     itemsadd.push({
    //         title: 'Positioning & Corrections',
    //         href: '/app/management/positioning-corrections',
    //         icon: PositioningIcon
    //     });
    // }
    
    itemsadd.push({
        title: 'Manage Exercise ',
        href: '/app/exercises',
        icon: ExerciseIcon
    });

    itemsadd.push({
        title: ' Manage Template  ',
        href: '/app/management/poc-templates',
        icon: POCIcon
    });

    itemsadd.push({
        title: t('menu.label.listbilling'),
        href: '/app/billing',
        icon: BillIcon
    });

    if (!isqatestMode) {
        itemsadd.push({
            title: 'QA- Provider Landing Page ',
            href: '/app/manager/provider/dashboard?qtest=1',
            icon: ProviderDashboardIcon
        });

        itemsadd.push({
            title: 'QA-  Admin Dashboard ',
            href: '/app/manager/admin/dashboard?qtest=1',
            icon: AdminDashboardIcon
        });

        itemsadd.push({
            title: 'QA- ' + t('menu.label.listclinical') + ' List',
            href: '/app/clinical/list?qtest=1',
            icon: ClinicalDashboard
        });

        itemsadd.push({
            title: 'QA- ' + t('menu.label.listclinical') + ' Card',
            href: '/app/clinical/detail?qtest=1',
            icon: ClinicalDashboard
        });

        itemsadd.push({
            title: 'QA- ' + t('menu.label.listbilling'),
            href: '/app/billing?qtest=1',
            icon: ManageBilling
        });

        itemsadd.push({
            title: 'QA-  Patient Chart Profile  ',
            href: '/app/patient/profile?qtest=1',
            icon: PatientChartProfile
        });
    }

    itemsadd.push({
        title: t('menu.label.managereport'),
        href: '/app/manage-reports',
        icon: ManagereportsIcon
    });

    if (roles == Roles.ORG_ADMIN) {
        itemsadd.push({
            title: t('menu.label.managescheduling'),
            href: '/app/manage-scheduling',
            icon: ManageSchedulingIcon
        });
    }

    if (roles == Roles.PRACTICE_ADMIN) {
        itemsadd.push({
            title: t('menu.label.manageconfig'),
            href: '/app/configuration',
            icon: ConfigBlueIcon
        });
    }

    if (roles == Roles.PROVIDER || roles == Roles.CSS || roles == Roles.PRACTICE_ADMIN) {
        itemsadd.push({
            title: t('menu.label.manageavailability'),
            href: '/app/manage-availability',
            icon: ManageAvailabilityIcon
        });

        itemsadd.push({
            title: t('menu.label.managescheduling'),
            href: '/app/manage-scheduling',
            icon: ManageSchedulingIcon
        });
    }

    sections.push({
        subheader: '',
        items: itemsadd
    });

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box className="drawerNav" height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box className={classes.logoBox} p={2}>
                        <RouterLink to="/">
                            <Logo/>
                        </RouterLink>
                    </Box>
                </Hidden>

                <Box p={1}>
                    {sections.map((section) => (
                        <List key={section.subheader}
                            subheader={(
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname
                            })}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" classes={{paper: classes.mobileDrawer}} onClose={onMobileClose} open={openMobile} variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor="left" variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: openSidebar,
                        [classes.drawerClose]: !openSidebar,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: openSidebar,
                            [classes.drawerClose]: !openSidebar,
                        }, classes.desktopDrawer),
                    }}
                >
                    <Divider/>
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
    openSidebar: PropTypes.bool
};

export default NavBar;