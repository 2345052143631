import React, {useState, useRef, useEffect} from 'react';
import {Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

function IdleMonitor() {
    let idleTimeout = (1000 * 60 * 10) + (1000); // 10 minute 1 seconds
    let idleLogout = 1000 * 60 * 1; //1 Minute
    let intervalTime = 1000; // Every 1 second
    let idle_count = '59';
    let modalFlag = 0;

    const mySetInterval = useRef(null);
    const [logoutCounter, setLogoutCounter] = useState(59);
    const [idleModal, setIdleModal] = useState(false);
    const {isAuthenticated, logout} = useAuth();

    const events = ['mousemove', 'click', 'keypress'];

    useEffect(() => {
        if (isAuthenticated) {
            for (let e in events) {
                window.addEventListener(events[e], sessionReset);
            }
            initInterval();
        }
    }, [isAuthenticated]);

    const initInterval = () => {
        modalFlag = 0;
        if (typeof window !== 'undefined') {
            if (localStorage.getItem('is_idle') !== undefined && localStorage.getItem('is_idle') !== null && localStorage.getItem('is_idle') !== 'null') {
                let localData = localStorage.getItem('is_idle');
                let dataArr = JSON.parse(localData);
                if (dataArr.timeout === 0 && dataArr.flag === 0) {
                    modalFlag = 0;
                    check();
                } else if (modalFlag === 0) {
                    setLocalStorageData(1);
                    check();
                }
            } else {
                setLocalStorageData(1);
            }
        }
    };

    const sessionReset = () => {
        if (typeof window !== 'undefined') {
            let localData = localStorage.getItem('is_idle');
            let dataArr = JSON.parse(localData);
            if (modalFlag === 0 && dataArr.timeout !== 0 && dataArr.flag !== 0) {
                setLocalStorageData(1);
                localStorage.setItem('is_idle_count', idle_count);
            }
        }
    };

    const check = () => {
        mySetInterval.current = setInterval(() => {
            if (typeof window !== 'undefined') {
                if (localStorage.getItem('is_idle') === undefined || localStorage.getItem('is_idle') === null || localStorage.getItem('is_idle') === 'null') {
                    handleLogOut();
                } else {
                    let localData = localStorage.getItem('is_idle');
                    let dataArr = JSON.parse(localData);
                    const now = Date.now().toString();
                    if (localStorage.getItem('is_video_start') !== undefined && localStorage.getItem('is_video_start') !== null
                        && localStorage.getItem('is_video_start') !== 'null' && localStorage.getItem('is_video_start') === 'yes') {
                        setLocalStorageData(1);
                    } else {
                        if (parseInt(now) > parseInt(dataArr.timeout) && modalFlag === 0) {
                            setIdleModal(true);
                            let count = localStorage.getItem('is_idle_count');
                            setLocalStorageData(0);
                            countDownTime(count);
                            setLogoutCounter(parseInt(count));
                            modalFlag = 1
                        } else if (dataArr.flag === 1 && modalFlag === 1) {
                            setIdleModal(false);
                            clearInterval(mySetInterval.current);
                            localStorage.setItem('is_idle_count', idle_count);
                            modalFlag = 0;
                        }
                    }
                }
            }
        }, intervalTime);
    };

    const setLocalStorageData = (flagVal, is_logout = false) => {
        const now = Date.now().toString();
        let arr = {flag: flagVal, is_logout: is_logout, timeout: (flagVal === 0) ? 0 : (parseInt(now) + idleTimeout)};
        if (typeof window !== 'undefined') {
            localStorage.setItem('is_idle', JSON.stringify(arr));
            localStorage.setItem('is_idle_count', idle_count);
        }
    };

    const countDownTime = (countDown) => {
        setIdleModal(true);
        mySetInterval.current = setInterval(() => {
            setLogoutCounter(countDown);
            if (typeof window !== 'undefined') {
                if (localStorage.getItem('is_idle') !== undefined && localStorage.getItem('is_idle') !== null && localStorage.getItem('is_idle') !== 'null') {
                    localStorage.setItem('is_idle_count', countDown);
                    if (--countDown === 0 && modalFlag === 1) {
                        handleLogOut();
                    }
                } else {
                    handleLogOut();
                }
            }
        }, intervalTime);
    };

    const handleExtendSession = () => {
        setIdleModal(false);
        setLocalStorageData(1);
        clearInterval(mySetInterval.current);
        localStorage.setItem('is_idle_count', idle_count);
    };

    const handleLogOut = () => {
        setIdleModal(false);
        logout();
        window.location.reload();
    };

    return (
        <Dialog open={idleModal} onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setIdleModal(false)
            }
        }}>
            <DialogTitle> Session expire warning</DialogTitle>
            <DialogContent>
                <DialogContentText> Your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to stay signed In?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" onClick={() => handleLogOut()}>Logout</Button>
                <Button variant="contained" color="default" onClick={() => handleExtendSession()}>Extend session</Button>
            </DialogActions>
        </Dialog>
    )
}

export default IdleMonitor;