import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface RoleAccessProps {
  roles?: any | undefined;
  children?: ReactNode;
}

const RoleAccess: FC<RoleAccessProps> = ({ children,roles }) => {
  const { user } = useAuth();
 if (typeof roles === "undefined")
{
  return (
    <>
      {children}
    </>
  );
 
}



if (!roles.includes(user.roles[0])) {
  return <Redirect to="/404" />;
}
    
  
  return (
    <>
      {children}
    </>
  );
 
};



export default RoleAccess;
