import axios from 'axios';
import { config } from 'src/config';
/*const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);*/


const axiosInstance = axios.create({
 baseURL: config.baseUrl,
 params: {
  apporigin: 'web'
}
});





axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default axiosInstance;
