import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { VideoCall as VideoCallIcon } from  '@material-ui/icons';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import Logo from 'src/components/Logo';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },


  toolbar: {
    minHeight: 64
  },
  logo: {
    height: 65,
    width: 48,
    marginBottom: 0, 
   
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('lg')]:{
      width:'100px'
    }
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp>
          <IconButton
            // color="inherit"
            className="mobDrawerIcon"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden> */}
        <Hidden mdDown  >
          <RouterLink to="/app" style={{outline:"none"}}>
            <Logo className={`${classes.logo} ${'patient-dashboard-logo'}`} />
          </RouterLink>
        </Hidden>
        {/* <Box
          ml={2}
          flexGrow={1}
        /> */}
        <Box
        ml={2}
        flexGrow={1}
        >
        <Typography variant="h6" component="h6" className="pDashboardHeading">
                              Patients
                      </Typography>
        </Box>



        
      
        {/* <Search /> */}
        {/* <Contacts /> */}
        {/* <Notifications /> */}
         {/*<Tooltip title="Teleport/ Meeting Room">
       <IconButton
          color="inherit"
          component={RouterLink}
          size="small"
          to="/app/meetingroom"
         >
        
          <SvgIcon>
            <VideoCallIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip> */}
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
