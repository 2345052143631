import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as ProviderDashboardIcon } from 'src/assets/provider-dashboard.svg';

const App = (props: SvgIconProps) => (

 <ProviderDashboardIcon />

  );
  
  export default App;

