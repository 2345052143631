import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Tooltip
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { VideoCall as VideoCallIcon } from  '@material-ui/icons';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import Logo from 'src/components/Logo';
import MenuIconNew from '@material-ui/icons/Menu';
import axios from 'src/utils/axios';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useAuth from 'src/hooks/useAuth';
interface TopBarProps {
  className?: string;
  openSidebar: boolean;
  onMobileNavOpen?: () => void;
  onNavHeaderOpen?: () => void;
  onNavHeaderClose?: () => void;
  
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },

  toolbar1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 10),
  
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbar: {
   
    minHeight: 64
  },
  logo: {
    height: 65,
    width: 48,
    marginBottom: 0, 
   
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('lg')]:{
      width:'100px'
    }
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  openSidebar,
  onMobileNavOpen,
  onNavHeaderOpen,
  onNavHeaderClose,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [patId, setpatId] = useState<any>('');
  console.log(pathname);
  //const { goToPreviousPath } = history.goBack();

  // const goToPreviousPath = () => {
  //   history.goBack()
  // }
  // if(goToPreviousPath.indexOf("app/patient/profile") !== -1){
  //   console.log('mohit nnnnnere')
  // }
  console.log(history);
  let prevLocation = '';
  let countlog = 0;
  history.listen(nextLocation => {
    console.log(prevLocation);
    let result1 = prevLocation.includes('app/patient/profile');
    if(result1 == true && countlog == 1)    
    {
      const result1_array = prevLocation.split('/').pop();
      console.log(result1_array);
      setpatId(result1_array)
      removeuserlogged(result1_array);
    }
    countlog++;
    console.log('mohitfull'+result1); // 👉️ true
    // ...
    prevLocation = nextLocation.pathname;
    // if(prevLocation.indexOf("app/patient/profile") !== -1){
    //   console.log('mohit ere')
    // }

    // if(typeof prevLocation == "string" && prevLocation.indexOf('app/patient/profile') > -1)
    //   console.log('mohit ere')
  });
  //console.log(prevLocation);
  // let prevLocation;

  // history.listen(nextLocation => {
  //   console.log(prevLocation);
  //   // ...
  //   prevLocation = nextLocation;
  //   if(prevLocation.indexOf("app/patient/profile") !== -1){
  //     console.log('mohit nnnnnere')
  //   }
  //   // if(typeof prevLocation == "string" && prevLocation.indexOf('app/patient/profile') > -1)
  //   //   console.log('mohit ere')
  // });
  //console.log(prevLocation);

    const removeuserlogged = async (patidd) => {
      console.log('herererer--9999'+patidd);
      let params = {};
      //params['patient_id'] = pid;
      params['provider_id'] = user.id;
      params['provider_name'] = user.firstName+' '+user.lastName;

      let userlogged = [{
          id: user.id, name: user.firstName+' '+user.lastName
      }]; 

      console.log(JSON.stringify(userlogged));

      const formData = new FormData();
      formData.append('userlogged', JSON.stringify(userlogged, null, 2));
      formData.append('patient_id', patidd);
      formData.append('provider_id', user.id);


      let url = '/userlogged/remove';
      console.log('3');

      try {
          let response = await axios.post(url, formData);
          //let response = await axios.post(url, params);
          console.log('4');

          if (response.data.success) {
              //setIsUserOn(true);
              if (response.data.data.id!='')
              {

                  //setUpdateinterationId(response.data.data.id);

              }
          } else {
            // setUpdateinterationId('');
          }


      } catch (err) {
          console.error(err);
          //setUpdateinterationId('');
          
      }
    };

  const handleDrawerOpen = () => {
    onNavHeaderOpen(); 
  };
  

  const handleDrawerClose = () => {
    onNavHeaderClose();
 
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            // color="inherit"
            className="mobDrawerIcon"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown  >
          <RouterLink to="/app" style={{outline:"none"}}>
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIconNew />
        </IconButton> */}
        <Hidden mdDown  >
        <div className={classes.toolbar1}>
          {openSidebar ? (
            <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon /> 
            </IconButton>
           ) :  <IconButton onClick={handleDrawerOpen}>
           <ChevronRightIcon /> 
           </IconButton>}
         
        </div>
        </Hidden>

        <Box
          ml={2}
          flexGrow={1}
        />



        
      
        {/* <Search /> */}
        {/* <Contacts /> */}
        {/* <Notifications /> */}
         {/*<Tooltip title="Teleport/ Meeting Room">
       <IconButton
          color="inherit"
          component={RouterLink}
          size="small"
          to="/app/meetingroom"
         >
        
          <SvgIcon>
            <VideoCallIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip> */}
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onNavHeaderOpen: PropTypes.func,
  openSidebar: PropTypes.bool
  
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
  onNavHeaderOpen: () => {},
  onNavHeaderClose: () => {},
  
 
};

export default TopBar;
