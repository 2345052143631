export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const config = {
  baseUrl: "https://apialliancecare.ptgenie.rehab/api",
  baseUrlAccount: "https://apialliancecare.ptgenie.rehab/api/V2/AccountControllerV2",
  videoServerBaseUrl: "https://video.ptgenie.com/videochat/meetpeer.php",
  siteUrl: "https://alliancecare.ptgenie.rehab/",
  enableSAML: false
};
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};