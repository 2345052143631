import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import  { Roles }  from 'src/types/roles';
interface GuestGuardProps {
  children?: ReactNode;
}
  
const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();
  if (isAuthenticated) {
   // console.log('roles:-', user.roles);
    if(user.roles[0]==Roles.SUPER_ADMIN ) // || user.roles[0]==Roles.ORG_ADMIN
    {
      return <Redirect to="/app/manager/admin/dashboard" />;
    }
    else if(user.roles[0]==Roles.ORG_ADMIN)
    {
      return <Redirect to="/app/manager/organization/dashboard" />;
    }
    else if(user.roles[0]==Roles.PRACTICE_ADMIN){
      return <Redirect to="/app/manager/practice/dashboard" />;
    }
    else if(user.roles[0]==Roles.CSS || user.roles[0]==Roles.PROVIDER){
      return <Redirect to="/app/manager/provider/dashboard" />;
    }
    else if(user.roles[0]==Roles.SERVICE){
      return <Redirect to="/app/management/patients" />;
    }
    // else
    // {
    //   return <Redirect to="/app/management/patients" />;
    // }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
