import React from 'react';
import type { FC } from 'react';
import classes from '*.module.css';
import {  makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import useAuth from '../hooks/useAuth';
import { config } from 'src/config';

interface LogoProps {
  [key: string]: any;
}
const useStyles = makeStyles((theme: Theme) => ({
logo:{
  [theme.breakpoints.down('lg')]:{
    width:'80px',
  }
}
}));
const Logo: FC<LogoProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const classes = useStyles();

  let backend_url = config.baseUrl.slice(0, -3);
  let logo_front_url = backend_url+"assets/logo/frontend-logo-front.svg";
  let logo_dashboard_url = backend_url+"assets/logo/frontend-logo-dashboard.svg";

  if (!isAuthenticated) {
    return  <img
    alt="Logo" className={classes.logo}
    src={logo_front_url}
    {...props}
  />;
  }
  return (
    <img
      alt="Logo" className={classes.logo}
      src={logo_dashboard_url}
      {...props}
    />
  );
}

export default Logo;
