import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import  { Roles }  from 'src/types/roles';           
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  userID: string | null;
  isforgotpassword : boolean;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;

  
  register: (email: string, name: string, password: string) => Promise<void>;
  resetpassword: (userName: string, password: string,code: string, confirmpassword:string) => Promise<void>;
  forgotpassword: (email: string) => Promise<void>;
  sso: (accessToken: string) => Promise<any>;
  aftersave: (user: any) => Promise<void>;
  
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type ResetPasswordAction = {
    type: 'RESETPASSWORD';
    payload: {
      user: User;
    };
};

type ForgotpasswordAction = {
  type: 'FORGOTPASSWORD';
  payload: {};
};

type AfterSaveAction = {
  type: 'AFTERSAVE';
  payload: {
    user: User;
  };
};




type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | ResetPasswordAction
  | ForgotpasswordAction
  | AfterSaveAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isforgotpassword: false,
  user: null,
  userID:null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      // Added By PV for Idle Session
      const now = Date.now().toString();
      let idleTimeout = (1000 * 60 * 10) + (1000); // 10 minute 1 seconds
      let arr = {flag: 1, is_logout: false, timeout: parseInt(now) + idleTimeout};
      if (typeof window !== 'undefined') {
          localStorage.setItem('is_idle', JSON.stringify(arr));
          localStorage.setItem('is_idle_count', '59');
      }
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('staysign');

    // Added By PV for Idle Session
    localStorage.removeItem('is_idle');
    localStorage.removeItem('is_idle_count');
    localStorage.removeItem('is_video_start');
    localStorage.removeItem('is_video_counter_start');

    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }


    case 'RESETPASSWORD': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }

     case 'AFTERSAVE': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }


    case 'FORGOTPASSWORD': {
      return {
        ...state,
        isforgotpassword: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  resetpassword: () => Promise.resolve(),
  forgotpassword: () => Promise.resolve(),
  sso: () => Promise.resolve(),
  aftersave: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const login = async (userName: string, password: string) => {
    const response = await axios.post('/Auth/Login', { userName, password });
 
             
    const accessToken = response.data.data.token;
    const user = response.data.data.user;
    const emr_access = response.data.data.emr_access;
    
    if(user.roles[0]!=Roles.PATIENT)
    {

    localStorage.setItem('emr_access', emr_access);
    localStorage.removeItem('adv_filter_applied');
    user['id']=response.data.data.id;
    let myJSON = JSON.stringify(user);
    localStorage.setItem('user', myJSON);
    setSession(accessToken);

    const users = JSON.parse(window.localStorage.getItem('user'));

    if(users.offset)
    {

   
    let offset=-(users.offset);
    if (offset > 0) {
      moment.tz.setDefault('Etc/GMT+'+offset);
  }
  else
  {
    moment.tz.setDefault('Etc/GMT'+offset);
  }

}

    
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  }
  else

  {

    enqueueSnackbar("You are not authorize to access", {
      variant: 'error',persist: false
    });
    history.push({
      pathname : '/login'
    });
  }
  };








/**
   * This method Reset Password with Vrification Code 
   */

  const resetpassword = async (email: string, password: string,code: string, confirmPassword:string) => {
   const response = await axios.post('/Auth/ResetPassword', { email, password,code,confirmPassword });
   const getusername = response.data.data.username;
   localStorage.setItem('getusername', getusername);
  };
  



    /**
   * This method takes logout and end session 
   */

  const logout = () => {
    axios.get('/logout');
    setSession(null);
    localStorage.removeItem('adv_filter_applied');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const sso = async (accessToken: string) => {
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      
      const { data } = await axios.get('/Account');
      const { user } = data.data
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
      return user;
    }
  }

  const forgotpassword = async (email: string) => {
    const response = await axios.post('/Auth/ForgotPassword', { email});
    dispatch({
      type: 'FORGOTPASSWORD',
      payload: {}
    });
  };

  const aftersave = async (user: any) => {

    let myJSON = JSON.stringify(user);
    localStorage.setItem('user', myJSON);

    const users = JSON.parse(window.localStorage.getItem('user'));
    if(users.offset){
      let offset=-(users.offset);
      if (offset > 0) {
        moment.tz.setDefault('Etc/GMT+'+offset);
      }else{
        moment.tz.setDefault('Etc/GMT'+offset);
      }
    }

    dispatch({
      type: 'INITIALISE',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          //const response = await axios.get<{ user: User; }>('/api/account/me');
        //  const response = await axios.get('/user');
          //const { user } = response.data;
          //const user = response.data;
          const user = JSON.parse(window.localStorage.getItem('user'));
          const users = JSON.parse(window.localStorage.getItem('user'));
          if(users.offset){
          let offset=-(users.offset);
          if (offset > 0) {
            moment.tz.setDefault('Etc/GMT+'+offset);
        }else{
          moment.tz.setDefault('Etc/GMT'+offset);
        }
      }
          // not sure which user is the right one, will test in a minute.
          // const user  = response.data.tempuser;;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        resetpassword,
        forgotpassword,
        sso,
        aftersave
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
