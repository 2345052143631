import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import TopBar from './TopBar';
import Footer from '../../components/InnerFooter';
import InnerFooter from '../../components/InnerFooter';
import { useHistory, useLocation } from 'react-router';

interface DashboardFullLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    paddingBottom:'4%',
    [theme.breakpoints.up('lg')]: {
      //paddingLeft: 256
      paddingLeft: 0
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardFullLayout: FC<DashboardFullLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const history = useHistory();
  const { pathname } = useLocation();
  // console.log(pathname);
  //const { goToPreviousPath } = history.goBack();
  // console.log(history);
  // const goToPreviousPath = () => {
  //   history.goBack()
  // }
  // if(goToPreviousPath.indexOf("app/patient/profile") !== -1){
  //   console.log('mohit nnnnnere')
  // }
  // console.log(history);
  let prevLocation ='';
  let countlog = 0;

  history.listen(nextLocation => {
    // console.log(prevLocation);
    let result1 = prevLocation.includes('app/patient/profile');
    // console.log('mohit'+result1); // 👉️ true
    if(result1 == true  && countlog == 1)
    { 
      const result1_array = prevLocation.split('/').pop();
      // console.log(result1_array);
      //removeuserlogged();
    }
    countlog++;
    // ...
    prevLocation = nextLocation.pathname;
    // if(prevLocation.indexOf("app/patient/profile") !== -1){
    //   console.log('mohit ere')
    // }

    
    // if(typeof prevLocation == "string" && prevLocation.indexOf('app/patient/profile') > -1)
    //   console.log('mohit ere')
  });
  //console.log(prevLocation);

  return (
   
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
          
        </div>
        
        
        
      </div>
      <InnerFooter/>
    </div>
  );
};

DashboardFullLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardFullLayout;
