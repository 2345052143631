import React, {Suspense, Fragment, lazy} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardFullLayout from 'src/layouts/DashboardFullLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import VerificationGuard from 'src/components/VerificationGuard';
import RoleAccess from 'src/components/RoleAccess';
import {Roles} from 'src/types/roles';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    roles?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

const UserRoles = Roles;

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen/>}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                const roles = route.roles;

                return (
                    <Route key={i} path={route.path} exact={route.exact}
                           render={(props) => (
                               <Guard>
                                   <Layout>
                                       {route.routes
                                           ? renderRoutes(route.routes)
                                           : <RoleAccess roles={roles}><Component {...props} /></RoleAccess>}
                                   </Layout>
                               </Guard>
                           )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login/:client?',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/samlauth/:client?',
        component: lazy(() => import('src/views/auth/SSOView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/forgot-password',
        component: lazy(() => import('src/views/auth/ForgotPasswordView'))
    },
    {
        exact: true,
        path: '/app',
        guard: GuestGuard,
        component: () => <Redirect to="/app/management/patients"/>
    },
    {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: () => <Redirect to="/app/management/patients"/>
    },
    {
        exact: true,
        guard: VerificationGuard,
        path: '/verification',
        component: lazy(() => import('src/views/auth/VerificationView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/reset-password',
        component: lazy(() => import('src/views/auth/ResetPasswordView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        path: '/meeting',
        component: lazy(() => import('src/views/extra/video/VideoRoomViewOut'))
    },
    {
        exact: true,
        path: '/register-unprotected',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        guard: AuthGuard,
        layout: DashboardFullLayout,
        path: '/app/patient/profile/:pid',
        component: lazy(() => import('src/views/patient/dashboard'))
    },
    {
        guard: AuthGuard,
        layout: DashboardFullLayout,
        path: '/app/patient/planofcare',
        component: lazy(() => import('src/views/patient/dashboard/PlanOfCare'))
    },
    {
        guard: AuthGuard,
        layout: DashboardFullLayout,
        path: '/app/patient/clinicalsummary',
        component: lazy(() => import('src/views/patient/dashboard/ClinicalSummary'))
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/meetingroom',
                component: lazy(() => import('src/views/extra/video/VideoRoomView'))
            },
            {
                exact: true,
                path: '/app/meetingroom/:id',
                component: lazy(() => import('src/views/extra/video/VideoRoomView'))
            },
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },
            {
                exact: true,
                path: '/app/management/patients',
                roles: [UserRoles.CSS, UserRoles.ORG_ADMIN, UserRoles.PRACTICE_ADMIN, UserRoles.PROVIDER],
                component: lazy(() => import('src/views/customer/PatientListView'))
            },
            {
                exact: true,
                path: '/app/management/patients/add',
                component: lazy(() => import('src/views/customer/PatientAddView'))
            },
            {
                exact: true,
                path: '/app/management/patients/edit/:id',
                component: lazy(() => import('src/views/customer/PatientAddView'))
            },
            {
                exact: true,
                path: '/app/management/users',
                component: lazy(() => import('src/views/customer/UserListView'))
            },
            {
                exact: true,
                path: '/app/management/users/add',
                component: lazy(() => import('src/views/customer/UserAddView'))
            },
            {
                exact: true,
                path: '/app/management/users/edit/:id',
                component: lazy(() => import('src/views/customer/UserAddView'))
            },
            {
                exact: true,
                path: '/app/management/organizations',
                roles: [UserRoles.SUPER_ADMIN, UserRoles.ORG_ADMIN, UserRoles.PRACTICE_ADMIN],
                component: lazy(() => import('src/views/organization/ListView'))
            },
            {
                exact: true,
                path: '/app/management/organizations/add',
                roles: [UserRoles.SUPER_ADMIN],
                component: lazy(() => import('src/views/organization/organizationAddView'))
            },
            {
                exact: true,
                path: '/app/management/practice/add',
                roles: [UserRoles.SUPER_ADMIN, UserRoles.ORG_ADMIN],
                component: lazy(() => import('src/views/organization/organizationAddView'))
            },
            {
                exact: true,
                path: '/app/management/organization/edit/:id',
                roles: [UserRoles.SUPER_ADMIN, UserRoles.ORG_ADMIN],
                component: lazy(() => import('src/views/organization/organizationAddView'))
            },
            {
                exact: true,
                path: '/app/management/practice/edit/:id',
                roles: [UserRoles.SUPER_ADMIN, UserRoles.ORG_ADMIN, UserRoles.PRACTICE_ADMIN],
                component: lazy(() => import('src/views/organization/organizationAddView'))
            },
            {
                exact: true,
                path: '/app/manager/provider/dashboard',
                roles: [UserRoles.CSS, UserRoles.PROVIDER],
                component: lazy(() => import('src/views/manager'))
            },
            {
                exact: true,
                path: '/app/manager/provider/dashboard/addmessage',
                component: lazy(() => import('src/views/manager/CommunicationChart'))
            },
            {
                exact: true,
                path: '/app/manager/admin/dashboard',
                roles: [UserRoles.SUPER_ADMIN, UserRoles.PRACTICE_ADMIN, UserRoles.ORG_ADMIN],
                component: lazy(() => import('src/views/adminDashboard'))
            },
            {
                exact: true,
                path: '/app/manager/organization/dashboard',
                component: lazy(() => import('src/views/organizationDashboard')),
                roles: [UserRoles.ORG_ADMIN],
            },
            {
                exact: true,
                path: '/app/manager/practice/dashboard',
                component: lazy(() => import('src/views/practiceDashboard')),
                roles: [UserRoles.PRACTICE_ADMIN],
            },
            {
                exact: true,
                path: '/app/reports/dashboard',
                component: lazy(() => import('src/views/reports/DashboardView'))
            },
            {
                exact: true,
                path: '/app/clinical/list',
                roles: [UserRoles.CSS, UserRoles.PROVIDER],
                component: lazy(() => import('src/views/cilinical/ListView'))
            },
            {
                exact: true,
                path: '/app/clinical/detail',
                roles: [UserRoles.CSS, UserRoles.PROVIDER],
                component: lazy(() => import('src/views/cilinical/CardView'))
            },
            {
                exact: true,
                roles: [UserRoles.SUPER_ADMIN],
                path: '/app/management/positioning-corrections',
                component: lazy(() => import('src/views/positioningCorrections'))
            },
            {
                exact: true,
                path: '/app/management/poc-templates',
                component: lazy(() => import('src/views/pocTemplates'))
            },
            {
                path: '/app/billing',
                roles: [UserRoles.PRACTICE_ADMIN, UserRoles.ORG_ADMIN, UserRoles.SUPER_ADMIN, UserRoles.CSS, UserRoles.PROVIDER],
                component: lazy(() => import('src/views/billing/ListView'))
            },
            {
                path: '/app/exercises',
                component: lazy(() => import('src/views/exercises/ListView'))
            },
            {
                path: '/app/exercises-v2',
                component: lazy(() => import('src/views/exercises-old/ListView'))
            },
            {
                path: '/app/exercises-v3',
                component: lazy(() => import('src/views/exercises-v3/ListView'))
            },
            {
                path: '/app/exercises',
                component: lazy(() => import('src/views/exercises/ListView'))
            },
            {
                //exact: true,
                path: '/app/manage-reports',
                //roles:[UserRoles.SUPER_ADMIN, UserRoles.PRACTICE_ADMIN, UserRoles.ORG_ADMIN],
                component: lazy(() => import('src/views/manageReports/'))
            },

            {
                exact: true,
                path: '/app/manage-availability',
                roles: [UserRoles.PROVIDER, UserRoles.PRACTICE_ADMIN, UserRoles.CSS],
                component: lazy(() => import('src/views/manageAvailability/'))
            },
            {
                exact: true,
                path: '/app/manage-scheduling',
                roles: [UserRoles.PROVIDER, UserRoles.PRACTICE_ADMIN, UserRoles.ORG_ADMIN, UserRoles.CSS],
                component: lazy(() => import('src/views/manageScheduling/'))
            },
            {
                exact: true,
                path: '/app/configuration',
                roles: [UserRoles.PRACTICE_ADMIN],
                component: lazy(() => import('src/views/configuration/'))
            },
            {
                component: () => <Redirect to="/404"/>
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: HomeView
            },
            {
                component: () => <Redirect to="/404"/>
            }
        ]
    }
];

export default routes;