import React, {
  useCallback, useEffect, useState
} from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import type { Theme } from 'src/theme'; 
import { APP_VERSION } from 'src/constants';

import {
  AppBar,
  Avatar,
  Box,
Dialog, Grid, IconButton,
  
  makeStyles,Divider,
 
  Toolbar,
  Typography,
  Slide,
  
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { config } from 'src/config';

interface InnerFooterProps {
    [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#fff',
      //paddingBottom: '5px',
      //paddingTop: '5px',
      padding:'10px',
      position:'fixed',
      bottom:'0px',
      width:'100%',
      boxShadow:'0px 8px 16px #928e8e',
      // paddingLeft:'256px',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 0
      },[theme.breakpoints.up('lg')]: {
        paddingLeft: 265
      }
      // zIndex:5555,
      
    },
    links: {
      color: '#818DBB',
      paddingLeft: '2%',
      paddingRight: '2%',
      fontFamily: "'Inter', 'Inconsolata', 'Monaco', 'Consolas', 'Courier New', 'Courier', 'monospace'",
      textDecoration:'none',
      fontSize:'12px',
      textAlign:'center',
      cursor:'pointer'
    }
    ,
    appBar: {
      position: 'relative',
      backgroundColor: '#3949ab !important',
  
    },

    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
}));
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const InnerFooter: FC<InnerFooterProps> = () => {
  const classes = useStyles();
  const version = '1.0';

  let backend_url = config.baseUrl.slice(0, -3);
  let privacy = backend_url+"assets/logo/Privacy_Policy.pdf";
  let term = backend_url+"assets/logo/Terms.pdf";
  const [openframe, setOpenframe] = React.useState(false);
    const [iframesrc, setIframesrc] = useState<string>('#');

    const openframewindow = (document) => {
      //let documenturl = document.imagepath;
      //console.log(document);
      //console.log(document.imagepath);
      setIframesrc(document);
      setOpenframe(true);
    };
    const handleClose = () => {
      setOpenframe(false);
    };

  return (
    <>
    <footer className={classes.footer}>
         <a  onClick={() => openframewindow(privacy)}  className={classes.links}>Privacy Policy</a>
        <Divider />
        <a  onClick={() => openframewindow(term)}  className={classes.links}>Terms of Use</a>
          {/* <Divider />
        <a  className={classes.links}>Disclaimers</a> */}
        <Divider />
        <p className={classes.links}>Version {APP_VERSION}</p>
    </footer>

<Dialog fullScreen open={openframe} onClose={handleClose} TransitionComponent={Transition}>
<AppBar className={classes.appBar}>
  <Toolbar>
    <Grid container direction="row" justify="space-between">
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" className={classes.title}>
          View Document
        </Typography>
      </Grid>
      <Grid item>
        <IconButton color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  </Toolbar>
</AppBar>
<Box width="100%" height="100%">
  <iframe src={iframesrc} width="100%" height="100%"  ></iframe>
</Box>
</Dialog></>

  );
};

InnerFooter.propTypes = {
};

export default InnerFooter;
