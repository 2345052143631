import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as ClinicalDashboard } from 'src/assets/clinical-dashbaord.svg';

const App = (props: SvgIconProps) => (

 <ClinicalDashboard  />

  );
  
  export default App;

