export const Roles = {
    SERVICE :  'data.roles.service',
    PATIENT :  'data.roles.patient',
    CSS :  'data.roles.css',
    SUPER_ADMIN :  'data.roles.superadmin',
    PRACTICE_ADMIN : 'data.roles.practiceadmin',
    ORG_ADMIN :  'data.roles.orgadmin',
    PROVIDER :  'data.roles.provider',
}





